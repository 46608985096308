@use "utils" as *;

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 100%; //default 16px
}

body {
  min-height: 100vh;
  background-color: $col-gray-light;
  overflow-x: hidden;
}

body.noscroll {
  overflow: hidden;
}

a,
a:visited,
a:hover {
  text-decoration: none;
}

ul {
  list-style: none;
}
