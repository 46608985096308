*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 100%;
}

body {
  min-height: 100vh;
  background-color: #fafafa;
  overflow-x: hidden;
}

body.noscroll {
  overflow: hidden;
}

a,
a:visited,
a:hover {
  text-decoration: none;
}

ul {
  list-style: none;
}

.u-mt-large {
  margin-top: 2.25rem !important;
}

.u-mt-small {
  margin-top: 1.5rem !important;
}

.u-mt-tiny {
  margin-top: 0.875rem !important;
}

.u-text-subheading {
  font-size: 0.875rem;
  font-weight: 700 !important;
  color: var(--col-txt1) !important;
}

.u-divider {
  width: 100%;
  height: 1px;
  margin: 12px 0;
  background-color: var(--col-bg-card);
}
@media (min-width: 40em) {
  .u-divider {
    display: none;
  }
}

.span-all {
  grid-column: 1/-1;
}

@media (max-width: 56.20em) {
  .hide-for-mobile {
    display: none !important;
  }
}

@media (min-width: 56.25em) {
  .hide-for-tablet {
    display: none !important;
  }
}

:root {
  --font-public-sans: "Public Sans", sans-serif;
}

body {
  font-family: var(--font-public-sans);
  font-size: 1.125rem;
}

h1,
h2,
h3 {
  margin-top: 0;
  font-weight: 400;
  color: #2d314d;
}

h1 {
  font-size: 2.5rem;
}
@media (min-width: 56.25em) {
  h1 {
    font-size: 3.25rem;
  }
}

h2 {
  font-size: 2.25rem;
}
@media (min-width: 56.25em) {
  h2 {
    font-size: 2.875rem;
  }
}

h3 {
  font-size: 1.5rem;
}

p {
  color: #9698a6;
  line-height: 1.5 !important;
  font-size: 1rem;
}
@media (min-width: 56.25em) {
  p {
    font-size: 1.125rem;
  }
}

.header {
  background-color: white;
  z-index: 100;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}
.header__wrapper {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0;
  padding: 1.5625rem;
  max-width: 100rem;
  margin: 0 auto;
}
@media (min-width: 56.25em) {
  .header__wrapper {
    padding: 0 1.5625rem;
  }
}
.header__navlist {
  display: flex;
  flex-direction: column;
}
@media (min-width: 56.25em) {
  .header__navlist {
    flex-direction: row;
    gap: 1.25rem;
  }
}
.header__navlist a {
  color: #9698a6;
  transition: all 0.2s ease-in-out;
  font-size: 0.875rem;
}
.header__navlist a:hover, .header__navlist a.active {
  color: #2d314d;
}
@media (min-width: 56.25em) {
  .header__navlist a {
    display: inline-block;
    padding: 1.5rem 0;
    font-size: 1rem;
    position: relative;
  }
  .header__navlist a::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.3125rem;
    background: linear-gradient(225deg, #31d35c, #2bb7da);
    opacity: 0;
  }
  .header__navlist a:hover::after, .header__navlist a.active::after {
    opacity: 1;
  }
}

.section {
  padding: 3.5rem 1.875rem;
}
@media (min-width: 56.25em) {
  .section {
    max-width: 100rem;
    padding: 4.5rem 1.5625rem;
    margin: 0 auto;
  }
}

.footer {
  position: relative;
  background-color: #2d314d;
  color: #f3f4f6;
}
.footer__wrapper {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding: 3.5rem 1.875rem;
  padding: 2.25rem 0;
}
@media (min-width: 56.25em) {
  .footer__wrapper {
    max-width: 100rem;
    padding: 4.5rem 1.5625rem;
    margin: 0 auto;
  }
}
@media (min-width: 56.25em) {
  .footer__wrapper {
    flex-direction: row;
    justify-content: space-between;
  }
}
.footer__item1 {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}
@media (min-width: 56.25em) {
  .footer__item1 {
    gap: 3rem;
  }
}
.footer__item1 img {
  height: 1.625rem;
}
@media (min-width: 56.25em) {
  .footer__item1 img {
    height: 1.5rem;
  }
}
.footer__socialIcons {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1.125rem;
}
.footer__socialIcons img {
  height: 1.75rem;
}
@media (min-width: 56.25em) {
  .footer__socialIcons img {
    height: 1.375rem;
  }
}
.footer__item2 {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.125rem;
}
@media (min-width: 56.25em) {
  .footer__item2 {
    width: 25%;
    height: 8.75rem;
    flex-wrap: wrap;
    align-items: start;
    gap: 2rem;
  }
}
.footer__item2 a {
  color: inherit;
}
.footer__item3 {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}
.footer__credit {
  position: absolute;
  left: 50%;
  bottom: 10px;
  transform: translateX(-50%);
  font-size: 0.75rem;
}
.footer__credit a {
  color: #2bb7da;
}

.hamburger {
  width: 1.5rem;
  height: 1.375rem;
  cursor: pointer;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0;
}
.hamburger span {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 0.125rem;
  background-color: #2d314d;
  transition: background-color 0s 0.2s;
}
.hamburger span::after, .hamburger span::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 0.125rem;
  background-color: #2d314d;
  transition: margin 0.2s 0.2s, transform 0.2s 0s;
  transform-origin: center;
}
.hamburger span::after {
  margin-top: 6px;
}
.hamburger span::before {
  margin-top: -6px;
}
@media (min-width: 56.25em) {
  .hamburger {
    display: none;
  }
}
.hamburger:hover {
  transform: scale(1.05);
}
.hamburger.active span {
  background-color: transparent;
  transition-delay: 0.2s;
}
.hamburger.active span::after, .hamburger.active span::before {
  transform-origin: center;
  margin-top: 0;
  background-color: #9698a6;
  transition: margin 0.2s 0s, transform 0.2s 0.2s;
}
.hamburger.active span::after {
  transform: rotate(45deg);
}
.hamburger.active span::before {
  transform: rotate(-45deg);
}

.overlay {
  position: fixed;
  top: 4.375rem;
  bottom: 0;
  left: -100%;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  transition: all 0.1s ease 0.3s;
  opacity: 0;
  background: linear-gradient(to bottom, #2d314d, transparent);
  z-index: 1000;
}
.overlay.active {
  left: 0;
  opacity: 1;
  transition: all 0.3s ease;
}
.overlay.active nav {
  transition: top 0.3s ease 0.3s;
  top: 20px;
}
.overlay nav {
  position: absolute;
  background-color: white;
  width: max(90%, 300px);
  padding: 1.25rem 0;
  top: -500px;
  transition: top 0.3s ease;
  border-radius: 5px;
}
.overlay__navlist li {
  text-align: center;
}
.overlay__navlist a {
  position: relative;
  display: block;
  padding: 0.625rem 0;
  color: #2d314d;
  font-weight: 400;
  z-index: 1;
}
.overlay__navlist a::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 5px;
  transform: scaleY(0);
  transition: transform 0.3s, width 0.4s cubic-bezier(1, 0, 0, 1) 0.3s;
  background: linear-gradient(225deg, #31d35c, #2bb7da);
  z-index: -1;
}
.overlay__navlist a:hover {
  color: white;
  transition: color 0s 0.5s;
}
.overlay__navlist a:hover::after {
  transform: scaleY(1);
  width: 100%;
}

.hero {
  position: relative;
}
@media (min-width: 56.25em) {
  .hero__wrapper {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 0;
    padding: 3.5rem 1.875rem;
    min-height: 39.375rem;
  }
}
@media (min-width: 56.25em) and (min-width: 56.25em) {
  .hero__wrapper {
    max-width: 100rem;
    padding: 4.5rem 1.5625rem;
    margin: 0 auto;
  }
}
.hero__image {
  min-height: 17.5rem;
  background-image: url(../images/image-mockups.png), url(../images/bg-intro-mobile.svg);
  background-repeat: no-repeat;
  background-position: bottom, center;
  background-size: 325px, cover;
}
@media (min-width: 56.25em) {
  .hero__image {
    position: absolute;
    top: 0;
    right: 0;
    width: 65%;
    overflow: visible;
    min-height: 47.1875rem;
    background-image: url(../images/image-mockups.png), url(../images/bg-intro-desktop.svg);
    background-position: top -115px right -140px, top -200px right -300px;
    background-size: 740px, 1100px;
  }
}
.hero__content {
  text-align: center;
  padding: 2.5rem 1.875rem 4rem;
}
@media (min-width: 56.25em) {
  .hero__content {
    text-align: start;
    width: 35%;
    padding: 0;
  }
}
.hero__content h1 {
  font-weight: 400;
}

.features {
  background-color: #f3f4f6;
}
.features__wrapper {
  padding: 3.5rem 1.875rem;
  display: grid;
  grid-template-columns: 1fr;
}
@media (min-width: 56.25em) {
  .features__wrapper {
    max-width: 100rem;
    padding: 4.5rem 1.5625rem;
    margin: 0 auto;
  }
}
@media (min-width: 56.25em) {
  .features__wrapper {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1.125rem;
  }
}
.features__heading, .features__subheading {
  text-align: center;
}
@media (min-width: 56.25em) {
  .features__heading, .features__subheading {
    text-align: start;
    width: 700px;
  }
}

.articles__wrapper {
  padding: 3.5rem 1.875rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 2rem;
}
@media (min-width: 56.25em) {
  .articles__wrapper {
    max-width: 100rem;
    padding: 4.5rem 1.5625rem;
    margin: 0 auto;
  }
}
.articles__heading {
  text-align: center;
}
@media (min-width: 56.25em) {
  .articles__heading {
    text-align: start;
    width: 700px;
  }
}

.cta {
  display: inline-block;
  background: linear-gradient(225deg, #31d35c, #2bb7da);
  padding: 0.625rem 1.75rem;
  border-radius: 100px;
  color: white;
  font-weight: 400;
  font-size: 0.875rem;
  font-weight: 700;
}
.cta.large {
  padding: 1rem 2.25rem;
}
.cta:hover {
  filter: brightness(1.1);
}

.featureCard {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0;
  gap: 1.25rem;
  margin-top: 2rem;
}
@media (min-width: 56.25em) {
  .featureCard {
    align-items: flex-start;
  }
}
.featureCard__content {
  text-align: center;
  word-wrap: break-word;
}
@media (min-width: 56.25em) {
  .featureCard__content {
    text-align: start;
  }
}

.articleCard {
  border-radius: 5px;
  background-color: white;
  overflow: hidden;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
}
@media (min-width: 56.25em) {
  .articleCard {
    margin-top: 2rem;
  }
}
.articleCard__image {
  height: 250px;
}
.articleCard__image img {
  height: 100%;
  width: 100%;
}
.articleCard__content {
  padding: 1.5625rem;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.articleCard__author {
  font-size: 0.75rem;
}
.articleCard__detail {
  text-align: start;
  font-size: 1rem;
}