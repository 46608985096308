@use "utils" as *;

.articleCard {
  border-radius: 5px;
  background-color: $col-white;
  overflow: hidden;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);

  @include bp("large") {
    margin-top: rem(32);
  }

  &__image {
    height: 250px;

    img {
      height: 100%;
      width: 100%;
    }
  }

  &__content {
    padding: rem(25);
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &__author {
    font-size: rem(12);
  }

  &__detail {
    text-align: start;
    font-size: rem(16);
  }
}
