@use "utils" as *;

.cta {
  display: inline-block;
  background: $gradient;
  padding: rem(10) rem(28);
  border-radius: 100px;
  color: $col-white;
  font-weight: 400;
  font-size: rem(14);
  font-weight: 700;

  &.large {
    // font-size: rem(16);

    padding: rem(16) rem(36);
  }

  &:hover {
    filter: brightness(1.1);
  }
}
